<template>
  <div class="product-section">
    <div class="section-header">
      <div class="product-section-title" v-if="!skeletonMode && section.title">{{ section.title }}</div>
      <ion-skeleton-text class="product-section-title skeleton-product-section-title" animated v-if="skeletonMode" />
      <div class="section-more" v-if="!skeletonMode && section.isOwnProductCategory" @click="editProductCategory()">Bearbeiten</div>
    </div>
    <NewCard v-if="addOwnCategory" goTo="addproductcategory" icon="bagHandleOutline" style="margin-top: 5px">
      Klicke hier um deine eigene Produktkategorie hinzuzufügen<br>(z.B. Frühstück, Haushalt, ...)
    </NewCard>
    <div v-if="!addOwnCategory" class="section-products-outside" @scroll="scrollingHappened($event)">
      <div class="section-products" v-if="!skeletonMode">
        <ProductCard
          v-for="r in visibleData"
          :product="r"
          :key="r.id"
          :showAvailability="true"
          :useAsAlternative="useAsAlternative"
          :editOwnProductCategory="editOwnProductCategory"
          @clicked="editProduct($event)"
          />
        <div class="space-after-cards"></div>
      </div>
       <div class="section-products" v-if="skeletonMode">
         <ion-skeleton-text v-show="!newCardSkeleton" animated v-for="i in 3" :key="i" class="skeleton-product-card" />
         <ion-skeleton-text v-show="newCardSkeleton" animated class="skeleton-new-card" />
        <div class="space-after-cards"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from '@/components/cards/ProductCard.vue'
import NewCard from '@/components/cards/NewCard.vue'
import {
  IonSkeletonText
} from '@ionic/vue'

export default {
  props: ['section', 'skeletonMode', 'useAsAlternative', 'editOwnProductCategory', 'newCardSkeleton'],
  components: {
    ProductCard,
    NewCard,
    IonSkeletonText
  },
  data () {
    return {
      visibleData: [],
      loadMoreNow: null,
      lastDataObj: this.section ? this.section.page : null
    }
  },
  watch: {
    loadMoreNow () {
      if (this.loadMoreNow === true) {
        this.loadNextPage()
      }
    },
    section () {
      if (this.section && this.section.page && this.section.page.hasOwnProperty('results')) {
        this.visibleData = this.section.page.results
      }
    }
  },
  computed: {
    addOwnCategory () {
      if (this.section && this.section.title && this.section.title === 'Eigene Kategorie hinzufügen') {
        return true
      }
    }
  },
  methods: {
    editProduct (event) {
      this.$emit('clicked', event)
    },
    editProductCategory () {
      this.$router.push({ name: 'addproductcategory', params: { category: JSON.stringify(this.section) } })
    },
    scrollingHappened (event) {
      const scrollThreshold = 2000
      const remainingScrollWidth = event.target.scrollWidth - event.target.scrollLeft
      const exceededThreshold = remainingScrollWidth < scrollThreshold
      this.loadMoreNow = exceededThreshold
    },
    loadNextPage() {
      if (this.lastDataObj && this.lastDataObj.next) {
        const url = this.lastDataObj.next
        this.$store.dispatch('apiSend', {url: url}).then(nextpage => {
          if (nextpage && nextpage.hasOwnProperty('results')) {
            this.lastDataObj = nextpage
            this.visibleData = this.visibleData.concat(nextpage.results)
          }
        })
      }
    }
  },
  mounted () {
    if (this.section && this.section.page && this.section.page.hasOwnProperty('results')) {
      this.visibleData = this.section.page.results
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.product-section {
  position: relative;
  margin-bottom: 20px;
}

.product-section-title {
  @extend .section-title;
  margin: 10px 0 3px 30px;
}

.skeleton-product-section-title {
  max-width: 50%;
  height: 20px;
}

.skeleton-section-product-card {
  height: 331px;
  max-width: 300px;
  margin: 10px;
  min-width: 80vw;
}

.section-products-outside {
  z-index: 1;
  overflow-x: scroll;
  padding: 20px 0;
  margin: -20px 0;
  overscroll-behavior-x: contain;
}

.section-products-outside::-webkit-scrollbar {
  display: none;
}

.section-products {
  display: flex;
  transform-origin: top left;
  margin-left: 15px;
}

.space-after-cards {
  height: auto;
  min-width: 20px;
}
</style>
